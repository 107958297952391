import React from "react";
import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
	hideActionModal,
	listInfoBoxContentsRequest,
	resetActionModal,
	resetErrorModal,
	resetTab,
	showActionModal,
	showModal,
} from "../../store/UI";
import { userRequest } from "../../store/User";
import { logoutRequest, isLoggedRequest } from "../../store/Login";
import { ButtonEnum, ModalActionButton, ModalEnum } from "../../beans";
import {
	isDesignRoute,
	isProductionRoute,
	isShippingRoute,
	routes,
} from "../../constants/routes";
import { close, account, signout, hamburger } from "../../constants/icons";
import PaddingManager from "../PaddingManager";
import Logo from "../Logo";
import Modal from "../Modal";
import Button from "../Button";
import Icon from "../Icon";
import { Wrap } from "./styles";
import InfoBox from "../InfoBox";
import { resetBilling } from "../../store/Billing";
import { resetFabric } from "../../store/Fabric";
import { resetOrders } from "../../store/Order";
import { getPrintLocationThunk } from "../../store/PrintLocation";
import { resetProduction } from "../../store/Production";
import { resetShipping } from "../../store/Shipping";
import { resetUserDesign } from "../../store/UserDesign";
import { FullStory } from "@fullstory/browser";
import { getCookie, setCookie } from "../../utils/cookies";
import { useTranslation } from "react-i18next";
import LangSwitcher from "../LangSwitcher";

type Props = {
	product?: boolean;
	productName?: string;
	productType?: string;
	hidden?: boolean;
	onClose?: MouseEventHandler<HTMLButtonElement>;
};

const Header = (props: Props) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { productName, productType, product } = props;
	const ui = useSelector((state: RootState) => state.ui);
	const user = useSelector((state: RootState) => state.user.data);
	const isLogged = useSelector((state: RootState) => state.login.logged);
	const [hamburgerOpen, setHamburgerOpen] = useState(false);
	const [width, setWidth] = useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	useEffect(() => {
		if (width <= 768) {
			if (!getCookie("mobile-popup"))
				dispatch(
					showActionModal({
						title: "Unlock the Full Potential!",
						error:
							'<div class="label-lg"><div><img src="https://fabbric-public-images.ams3.digitaloceanspaces.com/UI/platform-desktop.png" style="max-width:100%;" /></div><p>For the best experience, we recommend using our desktop version. Some features may be easier to access and use on a larger screen.</p></div>',
						actions: [
							{
								style: ButtonEnum.primary,
								onClick: () => {
									setCookie("mobile-popup", "true");
									dispatch(hideActionModal());
									goToHome();
								},
								label: "Got it, continue!",
							},
						],
					})
				);
		}
		dispatch(isLoggedRequest());
		if (isLogged && !user) {
			dispatch(userRequest());
		}
	}, [isLogged]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(listInfoBoxContentsRequest());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// TODO wait for user id from Colin
	useEffect(() => {
		const fs = getCookie("fs_init");
		if (user && !fs) {
			setCookie("fs_init", "true");

			FullStory("setIdentity", {
				uid: user.id.toString(),
				properties: {
					displayName: `${user.first_name} ${user.last_name}`,
					email: user.email,
				},
			});
		}
	}, [user]);

	const goToHome = () => {
		dispatch(getPrintLocationThunk());
		dispatch(resetProduction());
		dispatch(resetOrders());
		dispatch(resetUserDesign());
		dispatch(resetFabric());
		dispatch(resetTab());
		dispatch(resetShipping());
		dispatch(resetBilling());
		navigate(routes.home);
	};

	const closeHeader = () => {
		goToHome();
	};

	const renderInfoBox = () => {
		return (
			<InfoBox
				open={ui.infoBox.open}
				title={ui.infoBox.active.title}
				content={ui.infoBox.active.content}
				image={ui.infoBox.active.image}
			/>
		);
	};

	const renderModalError = () => {
		return (
			<Modal
				type={ModalEnum.error}
				key="errorModal"
				onClose={() => dispatch(resetErrorModal())}
				open={ui.errorModal.open}
				title={t("header.modal.errorTitle")}
				error
				content={
					<p dangerouslySetInnerHTML={{ __html: ui.errorModal.error }}></p>
				}
			/>
		);
	};

	const renderActionModal = () => {
		return (
			<Modal
				type={ui.actionModal.type}
				key="actionModal"
				onClose={() => dispatch(resetActionModal())}
				open={ui.actionModal.open}
				title={ui.actionModal.title || t("header.modal.attentionTitle")}
				error
				content={
					<>
						<div
							dangerouslySetInnerHTML={{ __html: ui.actionModal.error }}
						></div>
						<footer>
							{ui.actionModal.actions.map((action: ModalActionButton) => (
								<Button
									style={action.style}
									onClick={action.onClick}
									key={action.label}
								>
									{action.label}
								</Button>
							))}
						</footer>
					</>
				}
			/>
		);
	};

	const renderProductMenu = () => {
		return (
			<>
				{productType || productName ? (
					<div className="product-details">
						<div
							className={
								isDesignRoute(window.location.pathname)
									? "product active"
									: "product"
							}
						>
							{t("header.breadcrumb.design")}
						</div>
						<div className="product-details-divider"></div>
						<div
							className={
								isProductionRoute(window.location.pathname)
									? "product active"
									: "product"
							}
						>
							{t("header.breadcrumb.production")}
						</div>
						<div className="product-details-divider"></div>
						<div
							className={
								isShippingRoute(window.location.pathname)
									? "product active"
									: "product"
							}
						>
							{t("header.breadcrumb.checkout")}
						</div>
					</div>
				) : null}
			</>
		);
	};

	const renderNavigationMenu = () => {
		return (
			<ul className="navigationMenu" role="navigation">
				<li>
					<a
						onClick={() => navigate(routes.design)}
						className={
							window.location.pathname === routes.design ? "active" : ""
						}
					>
						{t("header.menu.design")}
					</a>
				</li>
				<li>
					<a
						onClick={() => navigate(routes.production)}
						className={
							window.location.pathname === routes.production ? "active" : ""
						}
					>
						{t("header.menu.production")}
					</a>
				</li>
			</ul>
		);
	};

	return (
		<>
			<Wrap
				className={product ? "product" : ""}
				role="header"
				$hidden={props.hidden}
			>
				<PaddingManager>
					<div className="header-container">
						<div className="logo">
							<Button style={ButtonEnum.link} onClick={() => goToHome()}>
								<Logo />
							</Button>
						</div>
						<div className="content">
							{/* PRODUCT BAR */}
							{product && renderProductMenu()}
							{/* NAVIGATION MENU */}
							{!product && isLogged && <>{renderNavigationMenu()}</>}
						</div>
						{!product && <LangSwitcher />}

						<div className="action" id="logged">
							{/* CLOSE PRODUCT MENU */}
							{product && (
								<Button style={ButtonEnum.icon} onClick={() => closeHeader()}>
									<Icon icon={close} />
								</Button>
							)}
							{!product && (
								<div className="userMenu">
									<Button
										style={ButtonEnum.icon}
										onClick={() => {
											dispatch(showModal(ModalEnum.login));
										}}
									>
										<Icon icon={account} />
										{t("header.account")}
									</Button>
									<div className="logged-actions">
										<div className="action-item-text">
											{t("header.hi")}, {user?.first_name}
										</div>
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => {
													dispatch(logoutRequest());
												}}
											>
												<Icon icon={signout} />
												{t("header.signout")}
											</Button>
										</div>
									</div>
								</div>
							)}
							{/* HAMBURGER */}
							{!product && (
								<div className="mobile-menu">
									<Button
										style={ButtonEnum.icon}
										onClick={() => {
											setHamburgerOpen(!hamburgerOpen);
										}}
									>
										{hamburgerOpen ? (
											<Icon icon={close} />
										) : (
											<Icon icon={hamburger} />
										)}
									</Button>
									<div
										className={`logged-actions ${hamburgerOpen ? "open" : "closed"}`}
									>
										<div className="action-item-text">
											{t("header.hi")}, {user?.first_name}
										</div>
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => navigate(routes.design)}
												className={
													window.location.pathname === routes.design
														? "active"
														: ""
												}
											>
												{t("header.menu.design")}
											</Button>
										</div>
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => navigate(routes.production)}
												className={
													window.location.pathname === routes.production
														? "active"
														: ""
												}
											>
												{t("header.menu.production")}
											</Button>
										</div>
										<div className="action-item">
											<Button
												style={ButtonEnum.menu}
												onClick={() => {
													dispatch(logoutRequest());
												}}
											>
												<Icon icon={signout} />
												{t("header.signout")}
											</Button>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</PaddingManager>
			</Wrap>
			{/* MODALS  */}
			{ui.errorModal.open && renderModalError()}
			{ui.actionModal.open && renderActionModal()}

			{renderInfoBox()}
		</>
	);
};

export default Header;

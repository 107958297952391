import React from "react";
import { MouseEventHandler } from "react";
import { ItemRender } from "../../beans";
import { Wrap } from "./styles";

type Props = {
	id?: string;
	canva: ItemRender;
	selected?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
};
const RenderThumbnail = (props: Props) => {
	const { id, selected, canva } = props;
	const { image } = canva;

	return (
		<Wrap $selected={selected} onClick={props.onClick} id={id}>
			<img className="thumb" src={image} alt="" />
		</Wrap>
	);
};

export default RenderThumbnail;

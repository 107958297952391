import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { OrderList,OrderSummary } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getErrors } from "../../utils/errors";
import { showErrorModal } from "../UI";
import { CreateOrderActionEnum } from "./actions";
import { Order } from "@stripe/stripe-js";

export interface OrderState {
  data: null | Order; // TODO type better
  summary: null | OrderSummary;
  confirmation: null | Order;
  orderList: OrderList[] | null;
  error: null | string;
  loading: boolean;
  loaded: boolean;
}

const initialState: OrderState = {
  data: null,
  error: null,
  summary: null,
  orderList: null,
  confirmation: null,
  loading: false,
  loaded: false,
};

// SAGAS
function* createOrderSaga(
  action: PayloadAction<{ production_id: string }>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(fetch, `${apiUrl}/orders/orders/`, {
      method: "POST",
      body: JSON.stringify({
        production_id: action.payload,
      }),
      headers: headers,
      credentials: "include",
    });

    const data = yield response.json();
    if (response.status === 200 || response.status === 201) {
      yield put(createOrderSuccess(data));
    } else {
      yield put(createOrderFailure(data));
    }
  } catch (error: unknown) {
    // console.error("ERROR", error);
  }
}

function* getOrderById(
  action: PayloadAction<{ production_id: string }>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${action.payload}/`,
      {
        headers: headers,
        credentials: "include",
      }
    );
    const data = yield response.json();

    if (response.ok) {
      yield put(getOrderByIdSuccess(data));
    } else {
      yield put(getOrderByIdFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: unknown) {
    // console.error("ORDER ERRORS", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(getOrderByIdFailure(errorMessage));
    yield put(showErrorModal(errorMessage));
  }
}

function* getOrderCompletedSaga(
  action: PayloadAction<{ orderId: string; code: string }>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${action.payload}/confirmation/`,
      {
        headers: headers,
        credentials: "include",
      }
    );
    const data = yield response.json();

    if (response.ok) {
      yield put(getOrderCompletedSuccess(data));
      yield put(createOrderSuccess({ id: data.id }));
    } else {
      yield put(getOrderCompletedFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: unknown) {
    // console.error("ORDER ERRORS", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(getOrderCompletedFailure(errorMessage));
    yield put(showErrorModal(errorMessage));
  }
}

function* getOrderSummarySaga(
  action: PayloadAction<{ orderId: string; code: string }>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();

    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${action.payload}/summary/`,
      {
        headers: headers,
        credentials: "include",
      }
    );
    const data = yield response.json();
    if (response.status === 200 || response.status === 201) {
      yield put(getOrderSummarySuccess(data));
    } else {
      yield put(getOrderSummaryFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: unknown) {
    // console.error("ORDER ERRORS", error);
    const errorMessage =
      "The server encountered an unexpected problem. Try again";
    yield put(getOrderSummaryFailure(errorMessage));
    yield put(showErrorModal(errorMessage));
  }
}

function* listOrderSaga(// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(fetch, `${apiUrl}/orders/orders/`, {
      headers: headers,
      credentials: "include",
    });

    const data = yield response.json();

    if (response.ok) {
      yield put(listOrderSuccess(data));
    } else {
      yield put(listOrderFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: unknown) {
    // console.error("ERROR", error);
  }
}

function* applyCouponSaga(
  action: PayloadAction<{ orderId: string; code: string }>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${action.payload.orderId}/apply_coupon/`,
      {
        method: "POST",
        body: JSON.stringify({
          code: action.payload.code,
        }),
        headers: headers,
        credentials: "include",
      }
    );

    const data = yield response.json();

    if (response.ok) {
      yield put(applyCouponSuccess(data));
    } else {
      yield put(applyCouponFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: unknown) {
    console.error("ERROR", error);
    yield put(showErrorModal(getErrors(error as Record<string, string | string[]>)));
  }
}

function* removeCouponSaga(
  action: PayloadAction<{ orderId: string }>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const headers = getAuthHeader();
    const response = yield call(
      fetch,
      `${apiUrl}/orders/orders/${action.payload}/remove_coupon/`,
      {
        method: "POST",
        headers: headers,
        credentials: "include",
      }
    );

    const data = yield response.json();

    if (response.ok) {
      yield put(removeCouponSuccess(data));
    } else {
      yield put(removeCouponFailure(data));
      yield put(showErrorModal(getErrors(data)));
    }
  } catch (error: unknown) {
    // console.error("ERROR", error);
  }
}

export const orderSlice = createSlice({
  name: "order",
  initialState,

  reducers: {
    resetOrders: () =>  initialState,
    listOrderRequest: (state) => {
      state.error = initialState.error;
      state.loading = true;
    },
    listOrderSuccess: (state, action: PayloadAction<OrderList[]>) => {
      state.orderList = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    listOrderFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createOrderRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
    },
    createOrderSuccess: (state, action: PayloadAction<Order>) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    createOrderFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOrderByIdRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
      state.loaded = false;
    },
    getOrderByIdSuccess: (state, action: PayloadAction<Order>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getOrderByIdFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOrderSummaryRequest: (state, _action) => {
      state.error = initialState.error;
      state.loading = true;
    },
    getOrderSummarySuccess: (state, action: PayloadAction<OrderSummary>) => {
      state.summary = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getOrderSummaryFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },

    getOrderCompletedRequest: (state) => {
      state.error = initialState.error;
      state.loading = true;
    },
    getOrderCompletedSuccess: (state, action: PayloadAction<Order>) => {
      state.confirmation = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getOrderCompletedFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    applyCouponRequest: (state, _action) => {
      state.error = initialState.error;
    },
    applyCouponSuccess: (state, action: PayloadAction<OrderSummary>) => {
      state.error = initialState.error;
      state.summary = action.payload;
    },
    applyCouponFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeCouponRequest: (state, _action) => {
      state.error = initialState.error;
    },
    removeCouponSuccess: (state, action: PayloadAction<OrderSummary>) => {
      state.error = initialState.error;
      state.summary = action.payload;
    },
    removeCouponFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

// TODO define actions better in actions.tsx
export const {
  resetOrders,
  listOrderRequest,
  listOrderSuccess,
  listOrderFailure,
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  getOrderByIdRequest,
  getOrderByIdSuccess,
  getOrderByIdFailure,
  getOrderSummaryRequest,
  getOrderSummarySuccess,
  getOrderSummaryFailure,
  getOrderCompletedRequest,
  getOrderCompletedSuccess,
  getOrderCompletedFailure,
  applyCouponRequest,
  applyCouponSuccess,
  applyCouponFailure,
  removeCouponRequest,
  removeCouponSuccess,
  removeCouponFailure,
} = orderSlice.actions;

export default orderSlice.reducer;

export function* sagas() {
  yield takeEvery(CreateOrderActionEnum.CREATE_ORDER_REQUEST, createOrderSaga);
  yield takeEvery(CreateOrderActionEnum.GET_ORDER_BY_ID_REQUEST, getOrderById);
  yield takeEvery(
    CreateOrderActionEnum.GET_ORDER_SUMMARY_REQUEST,
    getOrderSummarySaga
  );
  yield takeEvery(
    CreateOrderActionEnum.GET_ORDER_COMPLETED_REQUEST,
    getOrderCompletedSaga
  );
  yield takeEvery(CreateOrderActionEnum.LIST_ORDER_REQUEST, listOrderSaga);
  yield takeEvery(CreateOrderActionEnum.APPLY_COUPON_REQUEST, applyCouponSaga);
  yield takeEvery(
    CreateOrderActionEnum.REMOVE_COUPON_REQUEST,
    removeCouponSaga
  );
}

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PrintLocation } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface PrintLocationState {
  data: PrintLocation[];
  error: null | string;
  selected: PrintLocation | null;
  selectedLabel: PrintLocation | null;
}

const initialState: PrintLocationState = {
  data: [],
  error: null,
  selected: null,
  selectedLabel: null,
};

export const getPrintLocationThunk = createAsyncThunk(
  "printlocation/request",
  async () => {
    const response = await fetch(`${apiUrl}/designs/printlocation/`, {
      headers: { accept: "application/json" },
    });

    return await response.json();
  }
);

export const getLabelPrintLocationThunk = createAsyncThunk(
  "labelprintlocation/request",
  async () => {
    const response = await fetch(`${apiUrl}/designs/printlocation/label/`, {
      headers: { accept: "application/json" },
    });

    return await response.json();
  }
);

export const printLocationSlice = createSlice({
  name: "printLocation",
  initialState,
  reducers: {
    selectPrintLocation: (state, action: PayloadAction<PrintLocation>) => {
      if (action.payload) state.selected = action.payload;
    },
    selectLabelPrintLocation: (state, action: PayloadAction<PrintLocation>) => {
      if (action.payload) state.selectedLabel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrintLocationThunk.pending, (state) => {
      state.data = [];
    });
    builder.addCase(getPrintLocationThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.selected = action.payload[0];
    });
    builder.addCase(getPrintLocationThunk.rejected, (state, action) => {
      state.error = action.error?.message ?? "Unknown error";
    });

    builder.addCase(getLabelPrintLocationThunk.pending, (state) => {
      state.data = [];
    });
    builder.addCase(getLabelPrintLocationThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.selectedLabel = action.payload[0];
    });
    builder.addCase(getLabelPrintLocationThunk.rejected, (state, action) => {
      state.error = action.error?.message ?? "Unknown error";
    });
  },
});

export const { selectPrintLocation, selectLabelPrintLocation } =
  printLocationSlice.actions;

export default printLocationSlice.reducer;

import styled from "styled-components";

export const Wrap = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
  .action {
    position: relative;
    justify-content: end;
    display: flex;
    align-items: center;
    gap: 8px;
    text-wrap: nowrap;
    height: 50px;
    .lang {
      text-transform: uppercase;
    }
    .logged-actions,
    .lang-actions {
      position: absolute;
      top: calc(var(--headerHeight) - 13px);
      right: 0;
      background: var(--white);
      width: 240px;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      display: none;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .action-item-text,
      .action-item {
        width: 100%;
        height: 40px;
      }
      .action-item-text {
        padding: 0 8px;
      }
      .action-item {
        padding: 0 5px;
        display: flex;
        &:hover {
          background: var(--gray20);
        }
      }
    }
  }
  #logged:hover {
    .logged-actions {
      display: flex;
    }
  }
  #lang:hover {
    .lang-actions {
      display: flex;
    }
  }
`;

import styled from "styled-components";

export const Wrap = styled.div`
	width: 100%;

	.sticky-summary {
		position: sticky;
		top: 76px;
		width: 100%;
		.card {
			.card-container {
				gap: 0;
			}
		}
	}
	.summary-detail {
		padding: 8px 0;
		.row {
			display: flex;
			justify-content: space-between;
			gap: 16px;
			.price {
				color: var(--gray130);
				&.couponCode {
					color: var(--green100);
				}
			}
		}

		.edit-block {
			display: flex;
			gap: 16px;
			align-items: end;
			width: 100%;

			form {
				width: 100%;
				label {
					align-self: baseline;
				}
				input {
					width: 100%;
				}
			}
		}
		.vat-info {
			margin-top: 8px;
			text-align: right;
			color: var(--gray90);
		}
	}
	.production-disclaimer {
		text-align: right;
	}
	.button-container {
		margin-top: 24px;
		width: 100%;
		button {
			width: 100%;
		}
	}
	.coupon-details {
		margin-top: 16px;
	}
	.couponCode {
		margin-top: 8px;
		color: var(--green100);
		position: relative;
		&.code {
			margin-left: 20px;
			&:before {
				left: -20px;
				display: block;
				position: absolute;
				content: "";
				background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4IiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTkuNTkyNSAxNS43NTA3TDIuMjUgOC40MDgyVjkuOTA4MkMyLjI1IDEwLjMwNTcgMi40MDc1IDEwLjY4ODIgMi42OTI1IDEwLjk2NTdMOC41MzUgMTYuODA4MkM5LjEyIDE3LjM5MzIgMTAuMDcyNSAxNy4zOTMyIDEwLjY1NzUgMTYuODA4MkwxNS4zMTUgMTIuMTUwN0MxNS45IDExLjU2NTcgMTUuOSAxMC42MTMyIDE1LjMxNSAxMC4wMjgyTDkuNTkyNSAxNS43NTA3WiIgZmlsbD0iIzA1OEI2OCIvPgo8cGF0aCBkPSJNOC41MzUgMTMuMDU3NUM5LjEyIDEzLjY0MjUgMTAuMDcyNSAxMy42NDI1IDEwLjY1NzUgMTMuMDU3NUwxNS4zMTUgOC40QzE1LjkgNy44MTUgMTUuOSA2Ljg2MjUgMTUuMzE1IDYuMjc3NUw5LjQ3MjUgMC40MzVDOS4xODc2MSAwLjE1Njk3NiA4LjgwNTU3IDAuMDAwOTMzOTk1IDguNDA3NSAwTDMuNzUgMEMyLjkyNSAwIDIuMjUgMC42NzUgMi4yNSAxLjVWNi4xNTc1QzIuMjUgNi41NTUgMi40MDc1IDYuOTM3NSAyLjY5MjUgNy4yMTVMOC41MzUgMTMuMDU3NVpNNS40Mzc1IDIuMjVDNS42ODYxNCAyLjI1IDUuOTI0NiAyLjM0ODc3IDYuMTAwNDEgMi41MjQ1OUM2LjI3NjIzIDIuNzAwNCA2LjM3NSAyLjkzODg2IDYuMzc1IDMuMTg3NUM2LjM3NSAzLjQzNjE0IDYuMjc2MjMgMy42NzQ2IDYuMTAwNDEgMy44NTA0MUM1LjkyNDYgNC4wMjYyMyA1LjY4NjE0IDQuMTI1IDUuNDM3NSA0LjEyNUM1LjE4ODg2IDQuMTI1IDQuOTUwNCA0LjAyNjIzIDQuNzc0NTkgMy44NTA0MUM0LjU5ODc3IDMuNjc0NiA0LjUgMy40MzYxNCA0LjUgMy4xODc1QzQuNSAyLjkzODg2IDQuNTk4NzcgMi43MDA0IDQuNzc0NTkgMi41MjQ1OUM0Ljk1MDQgMi4zNDg3NyA1LjE4ODg2IDIuMjUgNS40Mzc1IDIuMjVaIiBmaWxsPSIjMDU4QjY4Ii8+Cjwvc3ZnPg==");
				width: 18px;
				height: 18px;
			}
		}
	}
`;

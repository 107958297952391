import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductionRange, ProductionRangeAvailability } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface ProductionRangeState {
	data: ProductionRange[];
	error: null | string;
	availableRange: ProductionRangeAvailability[] | [];
	sampleRange: ProductionRange | null;
	selectedRange: ProductionRange | null;
	loading: boolean;
	loaded: boolean;
}

const initialState: ProductionRangeState = {
	data: [],
	error: null,
	availableRange: [],
	sampleRange: null,
	selectedRange: null,
	loading: false,
	loaded: true,
};

export const getProductionRangeThunk = createAsyncThunk(
	"production-range/request",
	async (userDesignId: number) => {
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${userDesignId}/productionrangecost/starting_from_prices/`,
			{
				headers: { accept: "application/json" },
			}
		);

		return await response.json();
	}
);

export const getProductionRangeAvailability = createAsyncThunk(
	"production-range-availability/request",
	async () => {
		const response = await fetch(`${apiUrl}/designs/productionrange/`, {
			headers: { accept: "application/json" },
		});

		return await response.json();
	}
);

export const productionRangeSlice = createSlice({
	name: "productionRange",
	initialState,
	reducers: {
		selectProductionRange: (state, action: PayloadAction<ProductionRange>) => {
			state.selectedRange = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getProductionRangeThunk.pending, (state) => {
			state.data = [];
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(getProductionRangeThunk.fulfilled, (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(getProductionRangeThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(getProductionRangeAvailability.pending, (state) => {
			state.availableRange = [];
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(
			getProductionRangeAvailability.fulfilled,
			(state, action) => {
				state.sampleRange = action.payload[0];
				state.availableRange = action.payload;
				state.loading = false;
				state.loaded = true;
			}
		);
		builder.addCase(
			getProductionRangeAvailability.rejected,
			(state, action) => {
				state.error = action.error.message ?? "Unknown error";
				state.loading = false;
				state.loaded = true;
			}
		);
	},
});

export const { selectProductionRange } = productionRangeSlice.actions;
export default productionRangeSlice.reducer;

import React from "react";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
	getProductionByIdRequest,
	setProductionRequest,
} from "../../store/Production";
import {
	getProductionRangeAvailability,
	getProductionRangeThunk,
	selectProductionRange,
} from "../../store/ProductionRange";
import { getOrderByIdRequest, getOrderSummaryRequest } from "../../store/Order";
import { getUserDesignThunk } from "../../store/UserDesign";

import {
	ActionCardEnum,
	AlertEnum,
	ButtonEnum,
	ButtonTypeEnum,
	ProductionRange,
} from "../../beans";
import SizeSelector from "../../components/SizeSelector";
import Card from "../../components/Card";
import ActionCard from "../../components/ActionCard";
import ProductionDesigns from "../../components/ProductionDesigns";
import ProductionSummary from "../../components/ProductionSummary";
import PaddingManager from "../../components/PaddingManager";
import SampleSizeSelector from "../../components/SampleSizeSelector";
import SubsectionTitle from "../../components/SubsectionTitle";
import { Wrap } from "./styles";
import { routes } from "../../constants/routes";
import LoadingOptions from "../../components/LoadingOptions";

import {
	initialProduction_100,
	initialProduction_200,
	initialProduction_300,
	initialProduction_50,
} from "../../constants/productions";
import { calculateTotalProductionItems } from "../../utils/orders";
import Alert from "../../components/Alert";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { showInfoBox } from "../../store/UI";
import Icon from "../../components/Icon";
import { externalLink, phone, questionBaloon } from "../../constants/icons";

const OrderProduction = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const { designId, orderId, productionId } = useParams();

	// GLOBAL STATE
	const selectedItem = useSelector(
		(state: RootState) => state.userDesign?.data?.item_fabric?.item
	);
	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const productionRange = useSelector(
		(state: RootState) => state.productionRange.data
	);

	const summary = useSelector((state: RootState) => state.order.summary?.costs);
	const production = useSelector((state: RootState) => state.production.data);
	const selectedProductionRange = useSelector(
		(state: RootState) => state.productionRange.selectedRange
	);

	const isSample = production !== null ? production.sample : true;

	// LOCAL STATE
	const [sample, setSample] = useState(isSample);

	// HOOKS
	useEffect(() => {
		// data inconsistency - go home
		if (!designId || !orderId || !productionId) navigate(routes.home);

		// retreive userDesign and Production range
		if (designId) {
			dispatch(getProductionRangeAvailability());
			dispatch(getUserDesignThunk(designId));
			dispatch(getProductionRangeThunk(Number(designId)));
		}
		// retreive production info
		if (productionId) {
			dispatch(
				getProductionByIdRequest({
					designId: designId,
					productionId: productionId,
				})
			);
		}
		// retreive order
		if (orderId) {
			dispatch(getOrderSummaryRequest(orderId));
			dispatch(getOrderByIdRequest(orderId));
		}

		if (productionRange.length > 0 && !selectedProductionRange) {
			dispatch(selectProductionRange(productionRange[1]));
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// set productionRange to second range if not setted selectedProductionRange
		if (productionRange.length > 0 && !selectedProductionRange) {
			dispatch(selectProductionRange(productionRange[1]));
		}
	}, [productionRange]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (production) {
			const total = calculateTotalProductionItems(production?.sizes);
			if (total && productionRange) {
				const range = productionRange.find(
					(range) => total >= range.minimum && total <= range.maximum
				);

				if (range) {
					dispatch(selectProductionRange(range));
				} else {
					dispatch(
						selectProductionRange(productionRange[productionRange.length - 1])
					);
				}
			}
		}
	}, [production?.sizes]); // eslint-disable-line react-hooks/exhaustive-deps

	// HANDLERS
	const handleOrderSample = (sample: boolean) => {
		setSample(sample);

		if (!sample && selectedProductionRange) {
			handleOrderQuantity(selectedProductionRange);
		}
	};

	const handleOrderQuantity = (range: ProductionRange) => {
		setDefaultProdutcQuantity(range.minimum);
		dispatch(selectProductionRange(range));
	};

	const setDefaultProdutcQuantity = (minimum: number) => {
		const payload = {
			productionId: production?.id,
			orderId: orderId,
			body: initialProduction_50,
		};

		switch (minimum) {
			case 50:
				payload.body = initialProduction_50;
				dispatch(setProductionRequest(payload));
				return;
			case 100:
				payload.body = initialProduction_100;
				dispatch(setProductionRequest(payload));
				return;
			case 151:
			case 200:
				payload.body = initialProduction_200;
				dispatch(setProductionRequest(payload));
				return;
			case 301:
				payload.body = initialProduction_300;
				dispatch(setProductionRequest(payload));
				return;
			default:
				payload.body = initialProduction_50;
				dispatch(setProductionRequest(payload));
				return;
		}
	};

	return (
		<>
			<Header
				product
				productName={selectedItem?.name}
				productType={userDesign?.name}
				onClose={() => navigate(routes.home)}
			/>{" "}
			<Wrap>
				<PaddingManager>
					<div className="card-containers">
						<div className="production-options">
							<Card title={t("orderProduction.title")} active>
								{userDesign && production && (
									<ProductionDesigns
										edit
										production={production}
										userDesign={userDesign}
									/>
								)}

								{productionRange.length > 0 && selectedProductionRange ? (
									<div className="order-type">
										<SubsectionTitle title={t("orderProduction.orderType")} />
										<div className="order-type-options">
											<ActionCard
												onClick={() => handleOrderSample(true)}
												key={"sample-production"}
												type={ActionCardEnum.expanded}
												title={t("orderProduction.orderSample")}
												label={t("orderProduction.orderSampleDescription")}
												// price={`${priceFormat(productionRange[0].unit_total)} ${t("orderProduction.euroUnit")}`}
												selected={sample}
											/>
											<ActionCard
												onClick={() => handleOrderSample(false)}
												key={"order-production"}
												type={ActionCardEnum.expanded}
												title={t("orderProduction.orderProduction")}
												label={t("orderProduction.orderProductionDescription")}
												// price={`${priceFormat(selectedProductionRange.unit_total)}  ${t("orderProduction.euroUnit")}`}
												selected={!sample}
											/>
										</div>
										{productionRange.length > 0 && selectedProductionRange && (
											<div className="order-type-info">
												<Alert type={AlertEnum.success}>
													{sample ? (
														<span
															dangerouslySetInnerHTML={{
																__html: t("orderProduction.alertSample"),
															}}
														></span>
													) : (
														<span
															dangerouslySetInnerHTML={{
																__html: t("orderProduction.alertProduction"),
															}}
														></span>
													)}
												</Alert>
											</div>
										)}
									</div>
								) : (
									<LoadingOptions />
								)}

								{/* IF ORDER-TYPE */}
								{productionRange.length > 0 ? (
									<div className="order-quantity">
										{!sample ? (
											<>
												<SubsectionTitle
													title={t("orderProduction.sizeSelection")}
												>
													<Button
														style={ButtonEnum.link}
														onClick={() =>
															dispatch(
																showInfoBox({
																	open: true,
																	title: t("orderProduction.sizeGuide"),
																	key: "size_guide",
																})
															)
														}
													>
														{t("orderProduction.sizeGuide")}
													</Button>
												</SubsectionTitle>
												{production && orderId && (
													<div className="size-selector-container">
														<SizeSelector
															orderId={orderId}
															selectedProductionRange={selectedProductionRange}
														/>
													</div>
												)}
											</>
										) : (
											<>
												<SubsectionTitle
													title={t("orderProduction.sizeSelection")}
												>
													<Button
														style={ButtonEnum.link}
														onClick={() =>
															dispatch(
																showInfoBox({
																	open: true,
																	title: t("orderProduction.sizeGuide"),
																	key: "size_guide",
																})
															)
														}
													>
														{t("orderProduction.sizeGuide")}
													</Button>
												</SubsectionTitle>
												{orderId && <SampleSizeSelector orderId={orderId} />}
											</>
										)}
									</div>
								) : (
									<LoadingOptions />
								)}
							</Card>
							<div className="help-container">
								<Card>
									<div>
										<Icon icon={phone} />
									</div>
									<div className="title-sm">
										{t("orderProduction.helpAssistanceTitle")}
									</div>
									<div
										className="label-lg"
										dangerouslySetInnerHTML={{
											__html: t("orderProduction.helpAssistanceDescription"),
										}}
									/>

									<div className="label-lg">
										<Button
											type={ButtonTypeEnum.button}
											style={ButtonEnum.link}
											onClick={() =>
												window.open(
													"https://fabbric.com/pages/solicita-una-llamada-con-un-experto",
													"_blank"
												)
											}
										>
											{t("orderProduction.helpAssistanceButton")}
											<Icon icon={externalLink} />
										</Button>
									</div>
								</Card>
								<Card>
									<div>
										<Icon icon={questionBaloon} />
									</div>
									<div className="title-sm">
										{t("orderProduction.helpAnswerTitle")}
									</div>
									<div className="label-lg">
										{t("orderProduction.helpAnswerDescription")}
									</div>
									<div className="label-lg">
										<Button
											type={ButtonTypeEnum.button}
											style={ButtonEnum.link}
											onClick={() => {
												window.open(
													"https://help-center.fabbric.com/knowledge/fabbric-studio",
													"_blank"
												);
											}}
										>
											{t("orderProduction.helpAnswerButton")}{" "}
											<Icon icon={externalLink} />
										</Button>
									</div>
								</Card>
							</div>
						</div>
						<ProductionSummary
							summary={summary}
							orderId={orderId}
							designId={designId}
							productionId={productionId}
						/>
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default OrderProduction;

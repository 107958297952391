const productionPattern =
	"/design/:designId/order/:orderId/production/:productionId/";

const shippinpgPattern =
	"/design/:designId/order/:orderId/production/:productionId/shipping/";

const checkoutPattern =
	"/design/:designId/order/:orderId/production/:productionId/checkout/";

const designPattern = "/design/edit/:designId/";

const orderCompletedPattern = "/order/:orderId/completed/";

export const routes = {
	home: "/",
	access: "/access",
	design: "/design/",
	production: "/production",
	passwordReset: "/password/reset/",
};

export const goToEditDesign = (designId: number) => {
	return `/design/edit/${designId}/`;
};

export const goToOrderProduction = (
	designId: number,
	orderId: number,
	productionId: number
) => {
	return `/design/${designId}/order/${orderId}/production/${productionId}/`;
};

export const goToOrderShipping = (
	designId: string,
	orderId: string,
	productionId: string
) => {
	return `/design/${designId}/order/${orderId}/production/${productionId}/shipping/`;
};

export const goToOrderCheckout = (
	designId: string,
	orderId: string,
	productionId: string
) => {
	return `/design/${designId}/order/${orderId}/production/${productionId}/checkout/`;
};

/// CHECKS

export const isDesignRoute = (route: string) => {
	return isRouteMatchingPattern(route, designPattern);
};

export const isProductionRoute = (route: string) => {
	return isRouteMatchingPattern(route, productionPattern);
};

export const isShippingRoute = (route: string) => {
	return isRouteMatchingPattern(route, shippinpgPattern);
};

export const isCheckoutRoute = (route: string) => {
	return isRouteMatchingPattern(route, checkoutPattern);
};

export const isOrderCompletedRoute = (route: string) => {
	return isRouteMatchingPattern(route, orderCompletedPattern);
};

/// UTILS

function isRouteMatchingPattern(route: string, pattern: string) {
	const routeSegments = route.split("/");
	const patternSegments = pattern.split("/");

	//check if have same segments number
	if (routeSegments.length !== patternSegments.length) {
		return false;
	}

	// check every segment
	for (let i = 0; i < patternSegments.length; i++) {
		if (patternSegments[i].startsWith(":")) {
			// Il segmento del pattern è un parametro dinamico, salta il confronto
			continue;
		}
		if (routeSegments[i] !== patternSegments[i]) {
			return false;
		}
	}

	return true;
}

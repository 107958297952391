import React  from 'react';
import { AlertEnum, ButtonEnum, ButtonTypeEnum, ModalEnum } from "../../beans";
import Button from "../Button";
import Logo from "../Logo";
import { Wrap } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { loginRequest } from "../../store/Login";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import Alert from "../Alert";
import { useNavigate } from "react-router";
import { hideModal, showModal } from "../../store/UI";
import FormContainer from "../FormContainer";
import { routes } from "../../constants/routes";
import { loginForm, loginSchema } from "../../utils/form";
import { useTranslation } from "react-i18next";
import { sanitizeData } from "../../utils/sanitize";

const LoginForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const login = useSelector((state: RootState) => state.login);

  useEffect(() => {
    if (login.logged) {
      navigate(routes.home);
    }
  }, [login]); // eslint-disable-line react-hooks/exhaustive-deps

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields, isDirty, isValid },
  } = useForm<loginForm>({
    resolver: yupResolver(loginSchema),
    defaultValues: formData,
    shouldFocusError: false,
    mode: "onChange",
  });

  const handleOnSubmit = () => {
    dispatch(loginRequest(sanitizeData(formData)));
  };

  const handleChangeForm = async (data: Partial<loginForm>) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  const handleResetPassword = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(hideModal(ModalEnum.login));
    navigate(routes.passwordReset);
  };

  const renderErrors = () => {
    if (login.error)
      for (const [key] of Object.entries(login.error)) {
        if (login?.error[key])
          return login.error[key]?.map((error: string) => (
            <Alert key={key} full type={AlertEnum.error}>
              {error}
            </Alert>
          ));
        else {
          return <Alert type={AlertEnum.error}>{login.error[key]}</Alert>;
        }
      }
  };

  return (
    <Wrap>
      <div className="logo">
        <Logo />
      </div>
      <FormContainer>
        <form
          onChange={handleSubmit(handleChangeForm)}
          onSubmit={handleSubmit(handleChangeForm)}
        >
          <div className="form-row">
            <div className="input-row full">
              <label className="label-sm">{t("form.label.email")}</label>
              <input
                key="email"
                className={`size-selector-input`}
                type="text"
                {...register("email")}
              />
              {touchedFields["email"] && errors["email"] && (
                <small className="error-field">
                  {t(`${errors["email"]?.message}`)}
                </small>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="input-row full">
              <label className="label-sm">{t("form.label.password")}</label>
              <input
                key="password"
                className={`size-selector-input`}
                type="password"
                {...register("password")}
              />
              {errors["password"] && touchedFields["password"] && (
                <small className="error-field">
                  {t(`${errors["password"]?.message}`)}
                </small>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="input-row full reset-pwd">
              <Button
                type={ButtonTypeEnum.button}
                style={ButtonEnum.link}
                onClick={(e) => handleResetPassword(e)}
              >
                {t("registrationForm.forgotAccount")}
              </Button>
            </div>
          </div>

          <div className="error-row">{renderErrors()}</div>
          <div className="form-row">
            <Button
              style={ButtonEnum.primary}
              onClick={handleSubmit(handleOnSubmit)}
              disabled={!isDirty || !isValid}
              loading={login.loading}
              type={ButtonTypeEnum.button}
            >
              {t("registrationForm.signin")}
            </Button>
          </div>
        </form>
        <div className="description label-lg">
          {t("registrationForm.privacy.agree")}{" "}
          <a
            href="https://fabbric.com/policies/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            {t("registrationForm.privacy.terms")}
          </a>{" "}
          {t("registrationForm.privacy.aknowledge")}{" "}
          <a
            href="https://fabbric.com/policies/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t("registrationForm.privacy.policy")}
          </a>
        </div>
        <div className="description label-lg">
          {t("registrationForm.noAccount")}{" "}
          <Button
            onClick={() => {
              dispatch(hideModal(ModalEnum.login));
              dispatch(showModal(ModalEnum.registration));
            }}
            style={ButtonEnum.link}
          >
            {t("registrationForm.signup")}
          </Button>
        </div>
      </FormContainer>
    </Wrap>
  );
};

export default LoginForm;

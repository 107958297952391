import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthHeader } from "../../api/auth";
import { Billing } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface BillingState {
  data: Billing | null;
  error: null | string;
}

const initialState: BillingState = {
  data: null,
  error: null,
};

export const setBillingThunk = createAsyncThunk(
  "billing/request",
  async (billingRequest: { orderId: string; payload: Record<string, unknown> }) => {
    const { orderId, payload } = billingRequest;
    const headers = getAuthHeader();
    const response = await fetch(
      `${apiUrl}/orders/orders/${orderId}/billing/`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      }
    );
    if (response.ok) {
      return await response.json();
    }
  }
);

export const updateBillingThunk = createAsyncThunk(
  "billing/update",
  async (billingRequest: { orderId: string; payload: Record<string, unknown> }) => {
    const { orderId, payload } = billingRequest;
    const headers = getAuthHeader();
    const response = await fetch(
      `${apiUrl}/orders/orders/${orderId}/billing/`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: headers,
        credentials: "include",
      }
    );

    return await response.json();
  }
);

export const getBillingFromOrderThunk = createAsyncThunk(
  "billing/this_order",
  async (orderId: string) => {
    const headers = getAuthHeader();
    const response = await fetch(
      `${apiUrl}/orders/orders/${orderId}/billing/this_order/`,
      {
        headers: headers,
        credentials: "include",
      }
    );
    return await response.json();
  }
);
export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    resetBilling: (state) => {
      state.data = initialState.data;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setBillingThunk.pending, (state) => {
      state.data = null;
    });
    builder.addCase(setBillingThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(setBillingThunk.rejected, (state, action) => {
      state.error = action.error?.message ?? "Unknown error";
    });
    builder.addCase(updateBillingThunk.pending, (state) => {
      state.data = null;
    });
    builder.addCase(updateBillingThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(updateBillingThunk.rejected, (state, action) => {
      state.error = action.error?.message ?? "Unknown error";
    });
    builder.addCase(getBillingFromOrderThunk.pending, (state) => {
      state.data = null;
    });
    builder.addCase(getBillingFromOrderThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getBillingFromOrderThunk.rejected, (state, action) => {
      state.error = action.error?.message ?? "Unknown error";
    });
  },
});

// TODO define actions better in bindActionCreators.tsx
export const { resetBilling } = billingSlice.actions;

export default billingSlice.reducer;

import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.header<{ $hidden?: boolean }>`
	display: ${(props) => (props.$hidden ? "none" : "block")};

	position: sticky;
	z-index: 999;
	top: 0;
	background: var(--white);
	border-bottom: 1px solid var(--purple30);
	.header-container {
		display: flex;
		box-sizing: border-box;
		width: 100%;
		height: 52px;
		align-items: center;
		gap: 40px;
		flex-shrink: 0;

		.logo {
			color: var(--purple100);
		}

		.logo {
			width: 76px;
		}

		.content {
			width: 100%;
			.navigationMenu {
				display: flex;
				gap: 40px;
				list-style: none;
				li {
					cursor: pointer;
					a {
						font-family: "Helvetica Bold";
						font-size: 16px;
						font-style: normal;
						line-height: 19.2px;
						text-decoration: none;
						padding-bottom: 18px;
						color: var(--gray90);
						&:hover {
							color: var(--gray110);
							border-bottom: 1px solid var(--gray110);
						}
						&.active {
							color: var(--purple100);
							border-bottom: 1px solid var(--purple100);
						}
					}
				}
			}
		}

		.product-details {
			display: flex;
			justify-content: center;
			.product-details-divider {
				margin: 0 16px;
				background: var(--purple30);
				width: 1px;
				height: 20px;
			}

			.product {
				&.active {
					font-family: "Helvetica Bold";
				}
			}
		}
		.action {
			position: relative;
			justify-content: end;
			display: flex;
			align-items: center;
			gap: 8px;

			text-wrap: nowrap;
			height: 50px;

			.logged-actions,
			.lang-actions {
				position: absolute;
				top: calc(var(--headerHeight) - 13px);
				right: 0;
				background: var(--white);
				width: 240px;
				padding: 8px;
				border-radius: 4px;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
				display: none;
				flex-direction: column;
				align-items: start;
				justify-content: center;

				.action-item-text,
				.action-item {
					width: 100%;
					height: 40px;
				}
				.action-item-text {
					padding: 0 8px;
					display: flex;
					align-items: center;
				}
				.action-item {
					padding: 0 5px;
					display: flex;
					&:hover {
						background: var(--gray20);
					}
				}
			}
		}

		#logged,
		#lang {
			&:hover {
				.logged-actions:not(.open):not(.closed) {
					display: flex;
				}
			}
		}
	}

	.mobile-menu {
		display: none;
	}

	@media only screen and (max-width: ${breakpoints.mobile}px) {
		.header-container {
			justify-content: space-around;
			gap: 20px;
			.content {
				.navigationMenu {
					display: none;
				}
				.product-details {
					.product-details-divider {
						display: none;
					}
					.product {
						display: none;
						&.active {
							display: block;
						}
					}
				}
			}
			.action {
				> button {
					text-indent: -99999999px;
				}
				.logged-actions {
					&.open {
						display: flex;
						width: 100vw;
						left: 0;
						position: fixed;
					}
					&.closed {
						display: none;
					}
				}
			}
		}
		.userMenu {
			display: none;
		}
		.mobile-menu {
			display: block;
		}
	}
`;

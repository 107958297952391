import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { ButtonEnum } from "../../beans";
import { goToOrderProduction, routes } from "../../constants/routes";
import ProductionSummary from "../../components/ProductionSummary";
import ProductionDesigns from "../../components/ProductionDesigns";
import PaddingManager from "../../components/PaddingManager";
import ShippingForm from "../../components/ShippingForm";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Card from "../../components/Card";
import { Wrap } from "./styles";
import { useEffect } from "react";
import { getProductionByIdRequest } from "../../store/Production";
import { getUserDesignThunk } from "../../store/UserDesign";
import { userRequest } from "../../store/User";
import { getOrderSummaryRequest } from "../../store/Order";
import { useTranslation } from "react-i18next";

const OrderShipping = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const { designId, orderId, productionId } = useParams();

	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const production = useSelector((state: RootState) => state.production.data);
	const summary = useSelector((state: RootState) => state.order.summary?.costs);

	const selectedItem = useSelector(
		(state: RootState) => state.userDesign?.data?.item_fabric?.item
	);

	useEffect(() => {
		if (!designId || !orderId || !productionId) navigate(routes.home);
		dispatch(userRequest());
		dispatch(getOrderSummaryRequest(orderId));

		if (!userDesign?.id && designId) dispatch(getUserDesignThunk(designId));
		if (!production?.id && productionId)
			dispatch(
				getProductionByIdRequest({
					designId: designId,
					productionId: productionId,
				})
			);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const editProductionButton = () => {
		return designId && orderId && productionId ? (
			<Button
				style={ButtonEnum.link}
				onClick={() =>
					navigate(
						goToOrderProduction(
							Number(designId),
							Number(orderId),
							Number(productionId)
						)
					)
				}
			>
				{t("button.edit")}
			</Button>
		) : null;
	};

	return (
		<>
			<Header
				product
				productName={selectedItem?.name}
				productType={userDesign?.name}
				onClose={() => navigate(routes.home)}
			/>
			<Wrap>
				<PaddingManager>
					<div className="page-containers">
						<div className="page-content">
							<Card
								title={t("orderShipping.production")}
								action={editProductionButton()}
							>
								{userDesign && production && (
									<ProductionDesigns
										key={"production-design"}
										production={production}
										userDesign={userDesign}
									/>
								)}
							</Card>
							<Card title={t("orderShipping.shipping")} active>
								<ShippingForm
									orderId={orderId}
									designId={designId}
									productionId={productionId}
								/>
							</Card>
						</div>
						{summary && (
							<ProductionSummary
								summary={{
									subtotal: summary.subtotal,
									vat: summary.vat,
									total: summary.total,
									shipping: Number(summary.shipping),
									coupon: summary.coupon
										? {
												amount_discount: Number(summary.coupon.amount_discount),
												code: summary.coupon.code,
											}
										: undefined,
								}}
								orderId={orderId}
								designId={designId}
								productionId={productionId}
							/>
						)}
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default OrderShipping;

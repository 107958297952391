import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en/en.json";
import es from "./es/es.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    debug: true,
    keySeparator: ".",
    resources: {
      es: {
        translate: es,
      },
      en: {
        translate: en,
      },
    },
  });
export default i18n;

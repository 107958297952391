import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArtworkDetail as ArtworkDetailEnum, ButtonEnum } from "../../beans";
import { AppDispatch, RootState } from "../../store";
import ArtworkDetail from "../ArtworkDetail";
import Button from "../Button";
import Divider from "../Divider";
import SubsectionTitle from "../SubsectionTitle";
import { Wrap } from "./styles";
import { translatePrintLocationById } from "../../utils/translate";
import { patchUserDesignThunk } from "../../store/UserDesign";
import { useState } from "react";
import { selectTab } from "../../store/UI";
import LoadingOptions from "../LoadingOptions";
import { useTranslation } from "react-i18next";

const Summary = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const printLocations = useSelector(
		(state: RootState) => state.printLocations.data
	);
	const selectedFabric = useSelector(
		(state: RootState) => state.fabrics.selectedFabric
	);
	const selectedColorFabric = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.col_fabric
	);
	const artworks = useSelector(
		(state: RootState) => state.userDesign.data?.print_set
	);

	const [name, setName] = useState(userDesign?.name);
	const [showEditName, setShowEditName] = useState(false);

	const editProductName = () => {
		dispatch(
			patchUserDesignThunk({
				designId: userDesign?.id?.toString() || "",
				payload: { name: name },
			})
		);
		setShowEditName(false);
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setName(e.target.value);
	};

	if (!userDesign || !selectedFabric || !selectedColorFabric)
		return <LoadingOptions />;
	return (
		<Wrap>
			<SubsectionTitle title={t("summary.productName")}>
				<Button style={ButtonEnum.link} onClick={() => setShowEditName(true)}>
					{t("button.edit")}
				</Button>
			</SubsectionTitle>
			{!showEditName ? (
				<div className="body-sm">{userDesign.name}</div>
			) : (
				<div className="edit-block">
					<form onSubmit={editProductName}>
						<div className="input-row">
							<input
								id="name"
								name="name"
								type="text"
								value={name}
								onChange={handleChangeName}
								placeholder={userDesign.name}
							/>
						</div>
					</form>
					<Button style={ButtonEnum.primary} onClick={() => editProductName()}>
						{t("button.save")}
					</Button>
					<Button
						style={ButtonEnum.secondary}
						onClick={() => setShowEditName(false)}
					>
						{t("button.cancel")}
					</Button>
				</div>
			)}
			<Divider />
			<SubsectionTitle title={t("summary.manufactured")}></SubsectionTitle>
			<div
				className="body-sm"
				dangerouslySetInnerHTML={{
					__html: t("summary.manufacturedDescription"),
				}}
			/>
			<Divider />
			<SubsectionTitle title={t("summary.fabric")}>
				<Button
					style={ButtonEnum.link}
					id="fabric"
					onClick={() => dispatch(selectTab("fabric"))}
				>
					{t("button.edit")}
				</Button>
			</SubsectionTitle>
			<div className="body-sm">
				{selectedFabric.name} {selectedColorFabric.color.name}
			</div>
			{artworks ? (
				<>
					{artworks.map((artwork: ArtworkDetailEnum, k: number) =>
						artwork.location ? (
							<div className="artwork-details-summary" key={k}>
								<Divider />
								<SubsectionTitle
									title={t(
										`printLocation.${translatePrintLocationById(
											artwork.location,
											printLocations
										)}`
									)}
								/>
								<ArtworkDetail artwork={artwork} />
							</div>
						) : null
					)}
				</>
			) : null}
		</Wrap>
	);
};

export default Summary;

import styled from "styled-components";

export const Wrap = styled.div`
  height: 100%;
  min-height: 300px;

  .product-preview {
    height: 100%;
    min-height: 300px;
    position: relative;
    background: var(--gray20);
    border-radius: 4px;
    margin: auto;
    .product-image {
      object-fit: contain;
      box-sizing: border-box;
      max-width: 600px;
      width: 100%;
      max-height: calc(100vh - 100px);
    }
    .artwork-area {
      position: absolute;
      z-index: 2;

      svg {
        position: absolute;
      }
      img {
        transition: all 0.3s all ease-in-out;
      }

      &.front_center {
        width: 28%;
        height: 9.3%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.item_014,
        &.item_015 {
          top: 33%;
        }
        &.item_016 {
          top: 31.5%;
        }

        img {
          position: absolute;
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &.front_left {
        width: 4%;
        height: 3.3%;
        top: 27%;
        left: 61%;
        transform: translate(-50%, -50%);
        img {
          position: absolute;
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &.full_back {
        width: 32%;
        height: 36.5%;
        top: 37%;
        left: 50%;
        transform: translate(-50%, -50%);

        &.item_014,
        &.item_015 {
          top: 39.7%;
        }

        &.item_016,
        &.item_017 {
          top: 43.4%;
        }

        &.item_018 {
          top: 38.5%;
          left: 48.5%;
        }
        &.item_019 {
          top: 38.999%;
        }

        img {
          position: absolute;
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .artwork-preview {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 55px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  &.small {
    min-height: 100px;
    .product-preview {
      min-height: 100px;
    }
  }
`;

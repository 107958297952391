import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Fabric, Fabrics } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { put, call, select, StrictEffect, takeEvery } from "redux-saga/effects";
import { getUserDesign } from "../UserDesign/selectors";
import { getItemRenderListThunk } from "../ItemRenderList";
import { getAuthHeader } from "../../api/auth";
import { getUserDesignThunk, update } from "../UserDesign";
import { showErrorModal } from "../UI";
import { getErrors } from "../../utils/errors";
import { getProductionRangeThunk } from "../ProductionRange";

// Define the initial state
export interface FabricState {
  data: null | Fabrics[];
  error: null | string;
  selectedFabric: null | Fabrics;
  selectedColor: null | Fabric;
}

const initialState: FabricState = {
  data: null,
  error: null,
  selectedFabric: null,
  selectedColor: null,
};

export const fabricSlice = createSlice({
  name: "fabric",
  initialState,
  reducers: {
    resetFabric: (state) => {
      state.data = initialState.data;
      state.selectedFabric = initialState.selectedFabric;
      state.selectedColor = initialState.selectedColor;
    },
    selectFabric: (state, action: PayloadAction<Fabrics>) => {
      state.selectedFabric = action.payload;
    },
    selectFabricColor: (state, action: PayloadAction<Fabric>) => {
      state.selectedColor = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchFabricRequest: (state, _action) => {
      state.error = null;
    },
    fetchFabricSuccess: (state, action: PayloadAction<Fabrics[]>) => {
      state.data = action.payload;
      state.selectedFabric = state.selectedFabric?.code
        ? state.selectedFabric
        : action.payload[0];
      state.selectedColor = state.selectedFabric?.code
        ? state.selectedFabric.coloredfabric_set[0]
        : action.payload[0].coloredfabric_set[0];
    },
    fetchFabricFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

// Define the saga
function* fetchFabricSaga(
  action: PayloadAction<string>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  const designId = action.payload;

  try {
    const headers = getAuthHeader();
    const response = yield call(
      fetch,
      `${apiUrl}/designs/userdesigns/${designId}/fabric/`,
      {
        headers: headers,
      }
    );
    const data = yield response.json();
    yield put(fetchFabricSuccess(data));
    yield put(getProductionRangeThunk(Number(designId)));
  } catch (error: unknown) {
    // console.error("ERRROR FABBRIC", error);
    if (error instanceof Error) {
      yield put(fetchFabricFailure(error.message));
    } else {
      yield put(fetchFabricFailure("An unknown error occurred"));
    }
  }
}

function* updateFabricSaga(
  action: PayloadAction<{ id: number }>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
  try {
    const userDesign = yield select(getUserDesign());
    if (userDesign) {
      const headers = getAuthHeader();
      const response = yield call(
        fetch,
        `${apiUrl}/designs/userdesigns/${userDesign.id}/coloredfabric/select/`,
        {
          method: "POST",
          body: JSON.stringify({
            colored_fabric_id: action.payload.id,
          }),
          headers: headers,
          credentials: "include",
        }
      );
      const data = yield response.json();

      if (response.ok) {
        yield put(fetchFabricRequest(userDesign.id));
        yield put(
          getItemRenderListThunk({
            itemId: userDesign.item_fabric.item.id,
            colorId: userDesign.item_fabric.id,
            label: false,
          })
        );
        yield put(update(data));
        yield put(getUserDesignThunk(userDesign.id));
        yield put(getProductionRangeThunk(userDesign.id));
      } else {
        yield put(showErrorModal(getErrors(data)));
      }
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield put(showErrorModal(error.message));
      yield put(fetchFabricFailure(error.message));
    } else {
      yield put(showErrorModal("An unknown error occurred"));
      yield put(fetchFabricFailure("An unknown error occurred"));
    }
  }
}

export const {
  resetFabric,
  selectFabric,
  selectFabricColor,
  fetchFabricRequest,
  fetchFabricSuccess,
  fetchFabricFailure,
} = fabricSlice.actions;

export default fabricSlice.reducer;

export function* sagas() {
  yield takeEvery(fetchFabricRequest.type, fetchFabricSaga);
  yield takeEvery(selectFabricColor.type, updateFabricSaga);
}

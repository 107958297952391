import DOMPurify from "dompurify";

const sanitizeInput = (input: string) => {
  const sanitizedInput = DOMPurify.sanitize(input);
  return sanitizedInput;
};

export const sanitizeData = (data: Record<string, string | boolean>) => {
  if (data) {
    Object.keys(data).forEach((key) => {
      data[key as keyof object] = sanitizeInput(data[key].toString());
    });
  }
  return data;
};

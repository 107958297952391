import React  from 'react';
import { ButtonEnum } from "../../beans";
import { globe } from "../../constants/icons";
import Button from "../Button";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";
import { Wrap } from "./styles";

const LangSwitcher = () => {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Wrap>
      <div className="action" id="lang">
        <Button style={ButtonEnum.icon}>
          <Icon icon={globe} /> <span className="lang">{i18n.resolvedLanguage}</span>
        </Button>
        {/* LANG */}
        <div className="lang-actions">
          <div className="action-item-text title-sm">{t("lang.language")}</div>
          <div className="action-item">
            <Button
              style={ButtonEnum.menu}
              onClick={() => changeLanguageHandler("en")}
            >
              English
            </Button>
          </div>
          <div className="action-item">
            <Button
              style={ButtonEnum.menu}
              onClick={() => changeLanguageHandler("es")}
            >
              Español
            </Button>
          </div>
        </div>
      </div>
    </Wrap>
  );
};

export default LangSwitcher;

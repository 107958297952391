import React  from 'react';
import sizeMe from "react-sizeme";
import { Wrap } from "./styles";
import Icon from "../Icon";
import { cages } from "../../constants/cages";
import { getProperty } from "../../utils/render";
import { PrintLocation } from "../../beans";
import { convertCm2Px } from "../../utils/sizes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { addCage } from "../../store/UI";

type Props = {
  printLocation: PrintLocation;
  show?: boolean;
  clickable?: boolean;
  htmlId: number;
  size?: { width: number; height: number };  // inherithed from sizeMe
};
const ArtworkCage = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const { printLocation, show, htmlId, size } = props;
  const width = size?.width ?? 0;
  const height = size?.height ?? 0;

  useEffect(() => {
    const conversionUnit = width
      ? convertCm2Px(printLocation.real_width_cm, width)
      : 0;

    if (width > 0 && height > 0) {
      dispatch(
        addCage({
          id: htmlId,
          width: width.toString(),
          height: height.toString(),
          conversion_unit: conversionUnit,
        })
      );
    }
  }, [dispatch, width, height, htmlId, printLocation.real_width_cm]);
  return (
    <Wrap
      className={printLocation.placement}
      $show={show}
      $click={props.clickable}
      data-width={width}
      data-height={height}
      onClick={() => {
        // open file upload on click
        document.getElementById("fileUpload")?.click();
      }}
    >
      <Icon
        icon={getProperty(cages, printLocation.placement)}
        id={`cage_${htmlId}`}
        data-width={width}
        data-height={height}
      />
    </Wrap>
  );
};

export default sizeMe({ monitorHeight: true })(ArtworkCage);

export const front_center = {
  path: '<rect opacity="0.5" width="550" height="220" fill="#F2F1F4" /><rect x="0.5" y="0.5" width="550" height="220" stroke="#7745FF" stroke-dasharray="2 2" /> <line x1="275" y1="-2.36929e-08" x2="275" y2="220" stroke="#7745FF" stroke-dasharray="2 2" /><line x1="1.57622e-07" y1="110" x2="550" y2="110" stroke="#7745FF" stroke-dasharray="2 2"/>',
  viewBox: "0 0 550 220",
  initialWidth: "550",
  initialHeight: "220",
};

export const full_back = {
  path: '<rect opacity="0.5" width="440" height="616" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="439" height="615" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M220 4L220 612" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M4 308L436 308" stroke="#7745FF" stroke-dasharray="2 2"/>',
  viewBox: "0 0 440 616",
  initialWidth: "440",
  initialHeight: "616",
};

export const front_left = {
  path: '<rect opacity="0.5" width="66" height="66" fill="#F2F1F4"/><rect x="0.5" y="0.5" width="65" height="65" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M33 2L33 64" stroke="#7745FF" stroke-dasharray="2 2"/><path d="M2 33L64 33.0001" stroke="#7745FF" stroke-dasharray="2 2"/>',
  viewBox: "0 0 66 66",
  initialWidth: "17",
  initialHeight: "17",
};

export const label_neck = {
  path: '<rect opacity="0.5" x="1.5" y="1" width="127" height="25.4" fill="#F2F1F4"/><rect x="1" y="0.5" width="128" height="26.4" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="65.877" y1="1" x2="65.877" y2="26.4" stroke="#7745FF" stroke-dasharray="2 2"/><line x1="1.5" y1="13.0103" x2="128.5" y2="13.0103" stroke="#7745FF" stroke-dasharray="2 2"/>',
  viewBox: "0 0 130 28",
  initialWidth: "130",
  initialHeight: "28",
};

export const cages = {
  front_center: front_center,
  front_left: front_left,
  full_back: full_back,
  label_neck: label_neck,
};

export const MINIMUM_SIZE_CM = 2.5;
export const LABEL_MINIMUM_WIDTH_SIZE_CM = 4;
export const LABEL_MINIMUM_HEIGHT_SIZE_CM = 0.7;

import React from "react";
import { ReactNode } from "react";
import { Wrap } from "./styles";

type Props = {
	title?: string;
	action?: ReactNode;
	active?: boolean;
	sticky?: boolean;
	children?: React.ReactNode;
};

const Card = (props: Props) => {
	const { title, action, active, sticky, children } = props;

	return (
		<Wrap $active={active} $sticky={sticky} className="card">
			{(title || action) && (
				<div className="card-header">
					<div className="card-title title-lg">
						{title}
						{action}
					</div>
				</div>
			)}
			<div className="card-container">{children}</div>
		</Wrap>
	);
};

export default Card;

import React  from 'react';
import { Wrap } from "./styles";
type Props = {
  vertical?: boolean;
};
const Divider = (props: Props) => {
  return <Wrap className={props.vertical ? "vertical" : "horizontal"}></Wrap>;
};

export default Divider;

import React from "react";
import { MouseEventHandler, useEffect } from "react";
import { Wrap } from "./styles";
import Icon from "../Icon";
import { requestQuote } from "../../constants/icons";
import { useTranslation } from "react-i18next";

type Props = {
	size?: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
};
const RequestProductCard = (props: Props) => {
	const { t } = useTranslation();

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "//embed.typeform.com/next/embed.js";
		script.async = true;
		script.defer = true;

		document.body.appendChild(script);
	}, []);
	return (
		<Wrap onClick={props.onClick} key={"custom"} className={props.size}>
			<div className="request-box">
				<Icon icon={requestQuote} />
				<div
					className="description body-sm"
					dangerouslySetInnerHTML={{ __html: t("requestProduct.description") }}
				/>
				<div data-tf-live="01JBYP8KWS21CPQ4ACGAC0M6YW"></div>
			</div>
		</Wrap>
	);
};

export default RequestProductCard;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getAuthHeader } from "../../api/auth";
import { UserDesign } from "../../beans";
import { apiUrl } from "../../constants/endpoints";

export interface UserDesignState {
	data: UserDesign | null;
	error: null | string;
	loading: boolean;
	loaded: boolean;
}

const initialState: UserDesignState = {
	data: null,
	error: null,
	loading: false,
	loaded: false,
};
export const setUserDesignThunk = createAsyncThunk(
	"user_design/set",
	async (itemId: number) => {
		const headers = getAuthHeader();
		const response = await fetch(`${apiUrl}/designs/item/select/`, {
			method: "POST",
			body: JSON.stringify({ item_id: itemId }),
			headers: headers,
			credentials: "include",
		});
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}
		return await response.json();
	}
);

export const patchUserDesignThunk = createAsyncThunk(
	"user_design/patch",
	async (patchRequest: { designId: string; payload: object }) => {
		const headers = getAuthHeader();
		const { designId, payload } = patchRequest;
		const response = await fetch(`${apiUrl}/designs/userdesign/${designId}/`, {
			method: "PATCH",
			body: JSON.stringify(payload),
			headers: headers,
			credentials: "include",
		});
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}
		return await response.json();
	}
);

export const getUserDesignThunk = createAsyncThunk(
	"user_design/get",
	async (designId: string) => {
		const headers = getAuthHeader();
		const response = await fetch(`${apiUrl}/designs/userdesign/${designId}/`, {
			headers: headers,
			credentials: "include",
		});

		return await response.json();
	}
);

export const patchUserDesignPrintThunk = createAsyncThunk(
	"user_design/print/patch",
	async (patchRequest: {
		designId: string;
		printId: string;
		payload: object;
	}) => {
		const headers = getAuthHeader();
		const { designId, printId, payload } = patchRequest;
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${designId}/print/${printId}/`,
			{
				method: "PATCH",
				body: JSON.stringify(payload),
				headers: headers,
				credentials: "include",
			}
		);
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}

		return await response.json();
	}
);

export const createUserDesignLabelThunk = createAsyncThunk(
	"user_design/label/create",
	async (patchRequest: { designId: string; payload: object }) => {
		const headers = getAuthHeader();
		const { designId, payload } = patchRequest;
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${designId}/label/`,
			{
				method: "POST",
				body: JSON.stringify(payload),
				headers: headers,
				credentials: "include",
			}
		);
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}

		return await response.json();
	}
);

export const patchUserDesignLabelThunk = createAsyncThunk(
	"user_design/label/patch",
	async (patchRequest: {
		designId: string;
		labelId: string;
		payload: object;
	}) => {
		const headers = getAuthHeader();
		const { designId, labelId, payload } = patchRequest;
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${designId}/label/${labelId}/`,
			{
				method: "PATCH",
				body: JSON.stringify(payload),
				headers: headers,
				credentials: "include",
			}
		);
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}

		return await response.json();
	}
);

export const deleteUserDesignLabelThunk = createAsyncThunk(
	"user_design/label/delete",
	async (patchRequest: { designId: string; labelId: string }) => {
		const headers = getAuthHeader();
		const { designId, labelId } = patchRequest;
		const response = await fetch(
			`${apiUrl}/designs/userdesigns/${designId}/label/${labelId}/`,
			{
				method: "DELETE",
				headers: headers,
				credentials: "include",
			}
		);
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}

		return null;
	}
);

export const userDesignSlice = createSlice({
	name: "userDesign",
	initialState,

	reducers: {
		resetUserDesign: () => initialState,
		update: (state, action: PayloadAction<UserDesign>) => {
			state.data = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserDesignThunk.pending, (state) => {
			state.data = null;
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(getUserDesignThunk.fulfilled, (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(getUserDesignThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(setUserDesignThunk.pending, (state) => {
			state.loading = true;
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(setUserDesignThunk.fulfilled, (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(setUserDesignThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});

		builder.addCase(patchUserDesignThunk.pending, (state) => {
			state.data = null;
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(patchUserDesignThunk.fulfilled, (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(patchUserDesignThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});

		builder.addCase(patchUserDesignPrintThunk.pending, (state) => {
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(patchUserDesignPrintThunk.fulfilled, (state, action) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(patchUserDesignPrintThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});

		builder.addCase(createUserDesignLabelThunk.pending, (state) => {
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(createUserDesignLabelThunk.fulfilled, (state, action) => {
			if (state.data) state.data.label = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(createUserDesignLabelThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});

		builder.addCase(patchUserDesignLabelThunk.pending, (state) => {
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(patchUserDesignLabelThunk.fulfilled, (state, action) => {
			if (state.data) state.data.label = action.payload;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(patchUserDesignLabelThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});

		builder.addCase(deleteUserDesignLabelThunk.pending, (state) => {
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(deleteUserDesignLabelThunk.fulfilled, (state) => {
			if (state.data) state.data.label = null;
			state.loading = false;
			state.loaded = true;
		});
		builder.addCase(deleteUserDesignLabelThunk.rejected, (state, action) => {
			state.error = action.error.message ?? "Unknown error";
			state.loading = false;
			state.loaded = true;
		});
	},
});

export const { update, resetUserDesign } = userDesignSlice.actions;

export default userDesignSlice.reducer;

import React  from 'react';
import { ButtonEnum, ButtonTypeEnum, ModalEnum } from "../../beans";
import Button from "../Button";
import Logo from "../Logo";
import { Wrap } from "./styles";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { registrationRequest } from "../../store/Registration";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useNavigate } from "react-router";
import { hideModal, showModal } from "../../store/UI";
import FormContainer from "../FormContainer";
import { routes } from "../../constants/routes";
import { registrationForm, RegistrationSchema } from "../../utils/form";
import { useTranslation } from "react-i18next";
import { sanitizeData } from "../../utils/sanitize";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const RegistrationForm = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const login = useSelector((state: RootState) => state.login);
  const registration = useSelector((state: RootState) => state.registration);

  useEffect(() => {
    if (login.logged) {
      navigate(routes.home);
    }
  }, [registration, login]); // eslint-disable-line react-hooks/exhaustive-deps

  const switchAccessForm = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(hideModal(ModalEnum.registration));
    dispatch(showModal(ModalEnum.login));
  };
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password1: "",
    password2: "",
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm<registrationForm>({
    resolver: yupResolver(RegistrationSchema),
    defaultValues: formData,
    shouldFocusError: false,
    mode: "onChange",
  });

  const handleOnSubmit = (data: registrationForm) => {
    dispatch(registrationRequest(sanitizeData(data)));
  };

  const handleChangeForm = async (data: registrationForm) => {
    setFormData(data);
  };

  return (
    <Wrap>
      <div className="logo">
        <Logo />
      </div>
      <div className="description">{t("registrationForm.title")}</div>
      <FormContainer>
        <form
          onChange={handleSubmit(handleChangeForm)}
          onSubmit={handleSubmit(handleChangeForm)}
        >
          <div className="form-row">
            <div className="input-row">
              <label className="label-sm" htmlFor="first_name">
                {t("registrationForm.firstName")}
              </label>
              <input
                key="first_name"
                id="first_name"
                className={`size-selector-input`}
                type="text"
                {...register("first_name")}
              />
              {errors["first_name"] && (
                <small className="error-field">
                  {t(`${errors["first_name"]?.message}`)}
                </small>
              )}
            </div>
            <div className="input-row">
              <label className="label-sm" htmlFor="last_name">
                {t("registrationForm.lastName")}
              </label>
              <input
                key="last_name"
                id="last_name"
                className={`size-selector-input`}
                type="text"
                {...register("last_name")}
              />
              {errors["last_name"] && (
                <small className="error-field">
                  {t(`${errors["last_name"]?.message}`)}
                </small>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="input-row">
              <label className="label-sm" htmlFor="email">
                {t("registrationForm.email")}
              </label>
              <input
                key="email"
                id="email"
                className={`size-selector-input`}
                type="text"
                {...register("email")}
              />
              {errors["email"] && (
                <small className="error-field">
                  {t(`${errors["email"]?.message}`)}
                </small>
              )}
            </div>
            <div className="input-row">
              <label className="label-sm" htmlFor="phone">
                {t("registrationForm.phone")}
              </label>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    className="phone-input"
                    value={value}
                    onChange={onChange}
                    defaultCountry="ES"
                    withCountryCallingCode
                    placeholder="+34123456789"
                  />
                )}
              />
              {errors["phone"] && (
                <small className="error-field">
                  {t(`${errors["phone"]?.message}`)}
                </small>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="input-row">
              <label className="label-sm" htmlFor="password1">
                {t("registrationForm.password")}
              </label>
              <input
                key="password1"
                id="password1"
                className={`size-selector-input`}
                type="password"
                {...register("password1")}
              />
              {errors["password1"] && touchedFields["password1"] && (
                <small className="error-field">
                  {t(`${errors["password1"]?.message}`)}
                </small>
              )}
            </div>
            <div className="input-row">
              <label className="label-sm" htmlFor="password2">
                {t("registrationForm.passwordConfirm")}
              </label>
              <input
                key="password2"
                id="password2"
                className={`size-selector-input`}
                type="password"
                {...register("password2")}
              />
              {errors["password2"] && (
                <small className="error-field">
                  {t(`${errors["password2"]?.message}`)}
                </small>
              )}
            </div>
          </div>

          <div className="form-row">
            <Button
              style={ButtonEnum.primary}
              onClick={handleSubmit(handleOnSubmit)}
              loading={registration.loading}
            >
              {t("registrationForm.createAccount")}
            </Button>
          </div>
        </form>
      </FormContainer>
      <div className="description label-lg">
        {t("registrationForm.privacy.agree")}{" "}
        <a
          href="https://fabbric.com/policies/terms-of-service"
          target="_blank"
          rel="noreferrer"
        >
          {t("registrationForm.privacy.terms")}
        </a>{" "}
        {t("registrationForm.privacy.aknowledge")}{" "}
        <a
          href="https://fabbric.com/policies/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          {t("registrationForm.privacy.policy")}
        </a>
      </div>
      <div className="description label-lg">
        {t("registrationForm.alreadyAccount")} &nbsp;
        <Button
          type={ButtonTypeEnum.button}
          onClick={(e) => switchAccessForm(e)}
          style={ButtonEnum.link}
        >
          {t("registrationForm.signin")}
        </Button>
      </div>
    </Wrap>
  );
};

export default RegistrationForm;

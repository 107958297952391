import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../store";
import { selectRenderItemList } from "../../store/ItemRenderList";
import RenderThumbnail from "../RenderThumbnail";
import ArtworkCage from "../ArtworkCage";

import { Wrap } from "./styles";
import { ArtworkDetail, ItemRender } from "../../beans";
import {
	getPrintLocationThunk,
	selectPrintLocation,
} from "../../store/PrintLocation";
import {
	getCanvaFromView,
	getPrintLocationFromCanva,
} from "../../utils/render";
import LoadingCanva from "../LoadingCanva";
import Artwork from "../Artwork";

type Props = {
	canvas: ItemRender[] | null;
};

const RenderCanva = (props: Props) => {
	const { canvas } = props;
	const dispatch = useDispatch<AppDispatch>();

	// selectors
	const selectedPrintLocation = useSelector(
		(state: RootState) => state.printLocations.selected
	);

	const printLocations = useSelector(
		(state: RootState) => state.printLocations.data
	);

	const selectedItem = useSelector(
		(state: RootState) => state.userDesign?.data?.item_fabric?.item
	);

	const selectedFabricColor = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.col_fabric
	);

	const selectedCanva = useSelector(
		(state: RootState) => state.itemsRenderList.selected
	);

	const artworkDetail = useSelector(
		(state: RootState) => state.userDesign.data?.print_set
	);

	const artworkDetailRequest = useSelector(
		(state: RootState) => state.artworkDetails
	);
	const itemId = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.item?.code
	);

	const itemRenderListState = useSelector(
		(state: RootState) => state.itemsRenderList
	);
	const tab = useSelector((state: RootState) => state.ui.tab);

	const getCurrentArtwork = () => {
		if (!artworkDetail || !selectedPrintLocation) return;
		const artwork = artworkDetail.find(
			(artwork) =>
				Number(artwork?.location) === Number(selectedPrintLocation?.id)
		);
		setCurrentArtwork(artwork);
	};

	const [currentArtwork, setCurrentArtwork] = useState<
		ArtworkDetail | undefined
	>();

	// HOOKS

	useEffect(() => {
		dispatch(getPrintLocationThunk());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedItem && selectedFabricColor) getCurrentArtwork();
	}, [selectedItem, selectedFabricColor]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedPrintLocation && canvas) {
			const newCanva = getCanvaFromView(
				canvas,
				selectedPrintLocation.placement,
				selectedCanva?.view.includes("avatar")
			);
			if (newCanva) selectCanva(newCanva);
		}
	}, [selectedPrintLocation]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// get first if no canvas is selected
		if (canvas && !selectedCanva) selectCanva(canvas[0]);
		// update selectedCanva
		if (canvas && selectedCanva) {
			const newCanva = canvas.find(
				(canva) => canva.view === selectedCanva.view
			);
			newCanva && selectCanva(newCanva);
		}
	}, [canvas]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getCurrentArtwork();
	}, [artworkDetail, selectedPrintLocation]); // eslint-disable-line react-hooks/exhaustive-deps

	const selectCanva = (canva: ItemRender) => {
		if (canva) {
			dispatch(selectRenderItemList(canva));
			if (printLocations && selectedPrintLocation) {
				const printLocation = getPrintLocationFromCanva(
					canva,
					printLocations,
					selectedPrintLocation.placement,
					artworkDetail
				);
				if (printLocation) {
					dispatch(selectPrintLocation(printLocation));
				}
			}
		}
	};

	return canvas && selectedItem && selectedFabricColor ? (
		<Wrap>
			<div className="thumbnails">
				{canvas &&
					canvas.map((canva: ItemRender, k: number) => {
						return (
							<RenderThumbnail
								id={`thumb_${k}`}
								key={`thumb_${k}`}
								selected={canva?.image === selectedCanva?.image}
								onClick={() => selectCanva(canva)}
								canva={canva}
							/>
						);
					})}
			</div>
			<div className="product-preview">
				{selectedCanva && (
					<img
						className="product-image"
						src={selectedCanva.image}
						alt=""
						draggable="false"
					/>
				)}
				<div
					className={`artwork-area ${selectedPrintLocation?.placement} item_${itemId}`}
				>
					{selectedPrintLocation && (
						<ArtworkCage
							printLocation={selectedPrintLocation}
							show={tab.selected === "print"}
							htmlId={currentArtwork?.id ?? 0}
							clickable={!(currentArtwork && selectedPrintLocation)}
						/>
					)}
					{currentArtwork && selectedPrintLocation && (
						<Artwork
							itemId={itemId}
							currentArtwork={currentArtwork}
							printLocation={selectedPrintLocation}
							draggable={tab.selected === "print"}
						/>
					)}
				</div>
			</div>
			{artworkDetailRequest.loading && <LoadingCanva />}
			{itemRenderListState.loading && <LoadingCanva />}
		</Wrap>
	) : (
		<LoadingCanva />
	);
};

export default RenderCanva;

import React  from 'react';
import { ArtworkDetail as ArtworkDetailType, ButtonEnum } from "../../beans";
import Button from "../Button";
import { Wrap } from "./styles";
import { deleteArtworkDetailRequest } from "../../store/ArtworkDetail";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserDesignLabelThunk } from "../../store/UserDesign";
import { useTranslation } from "react-i18next";

type Props = {
  label?: boolean;
  artwork?: ArtworkDetailType;
};
const ArtworkDetail = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { artwork, label } = props;
  const userDesign = useSelector((state: RootState) => state.userDesign.data);

  const deleteArtwork = (artWork: ArtworkDetailType | undefined) => {
    if (label) {
      if (artWork && userDesign) {
        dispatch(
          deleteUserDesignLabelThunk({
            designId: userDesign.id,
            labelId: artWork?.id,
          })
        );
      }
    } else {
      if (artWork) dispatch(deleteArtworkDetailRequest(artWork.id));
    }
  };
  return artwork ? (
    <Wrap>
      <div className="artwork-image">
        {artwork.original_resized && (
          <img src={artwork.original_resized} alt="" />
        )}
      </div>
      <div className="artwork-details">
        <div>
          {!props.label && artwork.type && (
            <div className="label-lg">
              {t("artworkDetail.printTechnique")}:{" "}
              {t(`printType.label.${artwork.type}`)}
            </div>
          )}
        </div>

        <div className="artwork-actions">
          <Button
            style={ButtonEnum.link}
            onClick={() => deleteArtwork(props.artwork)}
          >
            {t("artworkDetail.delete")}
          </Button>
        </div>
      </div>
    </Wrap>
  ) : null;
};

export default ArtworkDetail;
